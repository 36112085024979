@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

.typography {
  font-family: 'Montserrat', sans-serif;
}
.typography--variant-h1 {
  font-size: 6rem;
}

.typography--variant-h2 {
  font-size: 3.75rem;
}

.typography--variant-h3 {
  font-size: 3rem;
}

.typography--variant-h4 {
  font-size: 2.125rem;
}

.typography--variant-h5 {
  font-size: 1.5rem;
}

.typography--variant-h6 {
  font-size: 1.25rem;
}

.typography--variant-subheading1 {
  font-size: 1rem;
}

.typography--variant-subheading2 {
  font-size: 0.875rem;
}

.typography--variant-body1 {
  font-size: 1rem;
}

.typography--variant-body1 {
  font-size: 0.875rem;
}

.typography--color-primary {
  color: #5900CC;
}

.typography--color-secondary {
  color: #656982;
}

.typography--color-terceary {
  color: #342C44;
}

.typography--color-error {
  color: #eb5757;
}

.typography--font-weight-700 {
  font-weight: 700;
}

.typography--font-weight-500 {
  font-weight: 500;
}

.typography--font-weight-400 {
  font-weight: 400;
}

.typography--font-weight-200 {
  font-weight: 200;
}
